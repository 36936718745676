import { Component, OnInit, OnDestroy } from '@angular/core';
import { routerTransition } from '../../router.animations';
import { Router, ActivatedRoute, NavigationEnd, Event, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { HomeService } from './home.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [routerTransition],
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  severErrorPopup = false;
  prevUrl: string;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private homeService: HomeService

  ) { }

  ngOnInit() {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.prevUrl = event.url;
      }
    });

    this.subscription = this.homeService.serverErr
      .subscribe(
        (serverErr: any) => {
          if (serverErr.serverr) {
            // console.log('serverErr: Home Component: ', serverErr.serverr);
            this.severErrorPopup = true;
          }
        });
  }
  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
  onReload() {
    this.severErrorPopup = false;
    let currentURL = this.router.url;
    this.router.navigate(['err-state']);
    setTimeout(() => {
      this.router.navigate([currentURL]);
      currentURL = null;
    }, 300);
  }
  checkFlow() {
    if (localStorage.getItem('pluginFlow')) {
      return true;
    }
  }

  logoRoute() {
    return '/dashboard/home';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
