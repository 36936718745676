import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AuthenticationService, APIInterceptor } from './services/index';
import { SignUpService } from './components/user-registration/sign-up/sign-up.service';
import { UploadService } from './components/data/upload/upload.service';
import { CardsService } from './components/data/prize/prize-section/cards.service';
import { HomeService } from './components/home/home.service';
import { RankingsService } from './components/user-dashboard/menu-bar/rankings.service';
import { LeaderboardService } from './modules/plugin-iframe/leaderboard/leaderboard.service';
import { FinancesService } from './components/user-dashboard/finances/finances.service';
import { UserPermissionsService } from './components/user-dashboard/social-links/user-permissions.service';
import { MarketPlaceService } from './components/user-dashboard/marketplace/marketplace.service';
import { MixpanelService } from './services/index';
import { AdvtsService } from './components/user-dashboard/advts/advts.service';
import { DataBreakdownService } from './components/user-dashboard/data-breakdown/data-breakdown.service';
import { TagsService } from './components/user-dashboard/tags/tags.service';
import { UserDataPermissionsService } from './components/user-dashboard/user-permissions/user-data-permissions.service';


import { AuthGuard } from './guards/index';
import { HomeComponent } from './components/home/home.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorStateComponent } from './components/error-state/error-state.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PageStepsComponent } from './components/page-steps/page-steps.component';
import { UtilService } from './services/util.service';
import { MainService } from './modules/landingpage/main/main.service';
import { PluginIframeModule } from './modules/plugin-iframe/plugin-iframe.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ErrorStateComponent,
    ContactUsComponent,
    PageStepsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PluginIframeModule
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    SignUpService,
    UploadService,
    CardsService,
    HomeService,
    RankingsService,
    LeaderboardService,
    FinancesService,
    UserPermissionsService,
    MarketPlaceService,
    MixpanelService,
    UtilService,
    MainService,
    AdvtsService,
    DataBreakdownService,
    TagsService,
    UserDataPermissionsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
