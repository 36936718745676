import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()

export class LeaderboardService implements OnInit {
  constructor(private http: HttpClient) { }

  ngOnInit() {}

  getLeaderboard() {
    return this.http.get<any>(`v1/117034/leaderboard?limit=500&social_id=&_=1549034763738`)
      .map(response => {
        const data = response;
        return data;
      });
  }

}
