import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AuthenticationService } from './../../../services/authentication.service';
import { MixpanelService } from '../../../services';

@Injectable()

export class MarketPlaceService implements OnInit {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private mixpanelService: MixpanelService
  ) {
  }

  selectedOffer = {
    imageUrl: '../../../../assets/ver-3/marketplace/card1.png'
  };

  ngOnInit() {
    this.authenticationService.setDtmxCookie();
  }

  marketPlaceOffers(type: number, featured: number) {
    const filter = encodeURIComponent(`type=${type}&status=1&liveProducts=1&isArchive=0&isFeatured=${featured}&page=1&size=50`);
    return (
      this.http.get(`api/v1/products/giftcards?filters=${filter}`)
        .map(
          (response: any) => response,
          (error: any) => console.log(error)
        )
    );
  }

  fetchCouponCode(productId: string) {
    // https://backend.mydatamarx.com/
    return (
      this.http.get(`api/v1/products/product/${productId}`)
        .map(
          (response: any) => response,
          (error: any) => console.log(error)
        )
    );
  }

  onGetApp(selectedOffer, itemType, itemIndex, screen) {
    this.mixpanelService.track(`Marketplace ${itemType} Clicked`, screen, {
      'Product Order': itemIndex + 1,
      'Product Title': selectedOffer.title,
      'Product Id': selectedOffer.id,
      'Product Description': selectedOffer.description,
      'Product Credit Price': selectedOffer.creditPointsRequiredToBuy,
      'Product Cash Price': selectedOffer.discountValue
    });
    this.setSelectedOffer(selectedOffer);
    this.addBlur();
  }

  addBlur() {
    const pageWrapper = document.querySelectorAll('.blur-bg');
    for (let i = 0; i < pageWrapper.length; i++) {
      pageWrapper[i].classList.add('blur');
    }
  }

  removeBlur() {
    const pageWrapper = document.querySelectorAll('.blur-bg');
    for (let i = 0; i < pageWrapper.length; i++) {
      pageWrapper[i].classList.remove('blur');
    }
  }

  getSelectedOffer() {
    return this.selectedOffer;
  }

  setSelectedOffer(selectedOffer) {
    this.selectedOffer = selectedOffer;
  }
}


