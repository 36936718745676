export const environment = {
  production: true,
  signUpApi: 'https://api.mydatamarx.com',
  hermesAPI: 'https://hermes.monetizeme.com',
  backendAPI: 'https://backend.mydatamarx.com',
  apolloAPI: 'https://apollo.mydatamarx.com',
  // TODO :: change when prod environment is created
  plaidEnvironment: 'development',
  sdkUserUrl: 'https://widgetapi.mydatamarx.com/api.php?method=user',
  monetizemeUrl: 'https://monetizeme.com',
  configUrl: 'https://config.monetizeme.com/ui/prod/MonetizeMeUIConfig-prod'
};
