import { Component } from '@angular/core';
import { routerTransition } from './router.animations';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-root',
  animations: [routerTransition],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
