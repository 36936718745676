import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CountoModule } from 'angular2-counto';

import { GaugeModule } from 'angular-gauge';
import { pluginIframeRoutes } from './plugin-iframe-routing.module';
import { PointsGaugeComponent } from './plugin-contest/points-gauge.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { SweepstakesComponent } from './sweepstakes/sweepstakes.component';
import { FaqComponent } from './faq/faq.component';


@NgModule({
  declarations: [
    PointsGaugeComponent,
    LeaderboardComponent,
    SweepstakesComponent,
    FaqComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(pluginIframeRoutes),
    GaugeModule.forRoot(),
    CountoModule
  ],
  exports: [
    PointsGaugeComponent,
    FaqComponent
  ]
})
export class PluginIframeModule { }
