import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { UtilService } from './util.service';

declare const mixpanel: any;

export enum MMScreen {
  homepage = 'homepage',
  signup = 'signup',
  verification = 'verification',
  rankings = 'rankings',
  marketplace = 'marketplace',
  appInstall = 'appInstall',
  login = 'login',
  fbUpload = 'fbUpload',
  newPassword = 'newPassword',
  usernameRetrieval = 'usernameRetrieval',
  newsFeed = 'newsFeed',
  sliderLinkClick = 'sliderLinkClicked'
}

@Injectable()
export class MixpanelService {

  constructor(
    private utilService: UtilService
  ) {
    this.initMixpanelUser();
  }

  public initMixpanelUser() {
    if (this.utilService.getUserData() && this.utilService.getUserData().userId) {
      const mixpanelPeopleProperties = {};
      const userData = this.utilService.getUserData();
      mixpanel.identify(userData.userId);
      Object.assign(mixpanelPeopleProperties, {
        $email: userData.email,
        $phone: userData.phone,
        $distinct_id: userData.userId,
        $first_name: userData.firstName,
        $last_name: userData.lastName,
        username: userData.username,
        'User Id': userData.userId,
        'MonetizeMe Web': true
      });
      if (this.utilService.getSeccode().length > 0) {
        Object.assign(mixpanelPeopleProperties, {
          'Seccode': this.utilService.getSeccode()
        });
      }
      if (this.utilService.getSub1().length > 0) {
        Object.assign(mixpanelPeopleProperties, {
          'Sub1': this.utilService.getSub1()
        });
      }
      mixpanel.people.set(mixpanelPeopleProperties);
    }
  }

  public track(title: string, screen: MMScreen, mixpanelProperties = {}) {
    mixpanelProperties['Source'] = 'MonetizeMe Web';
    mixpanelProperties['Screen'] = screen;
    if (this.utilService.getSeccode().length > 0) {
      mixpanelProperties['Seccode'] = this.utilService.getSeccode();
    }
    if (this.utilService.getSub1().length > 0) {
      mixpanelProperties['Sub1'] = this.utilService.getSub1();
    }
    if (this.utilService.getUserData()) {
      mixpanelProperties['User Id'] = this.utilService.getUserData().userId;
      mixpanelProperties['distinct_id'] = this.utilService.getUserData().userId;
    }
    if (document.referrer) {
      mixpanelProperties['Referrer'] = document.referrer;
      mixpanelProperties['Referrer Domain'] = document.referrer.match(/:\/\/(.[^/]+)/)[1];
    }
    if (this.utilService.getRedirect().length > 0) {
      mixpanelProperties['Redirect'] = this.utilService.getRedirect();
    }
    if (this.utilService.getDirect().length > 0) {
      mixpanelProperties['Direct'] = this.utilService.getDirect();
    }
    if (this.utilService.getReferralCode().length > 0) {
      mixpanelProperties['Referral Code'] = this.utilService.getReferralCode();
    }
    mixpanel.track(title, mixpanelProperties);
  }

  public setRanking(userRanking: any) {
    mixpanel.people.set({
      'Contest Social Id': userRanking.social_id,
      'Contest Points': userRanking.point,
      'Contest Referral Count': userRanking.verified_leads.indirect + userRanking.verified_leads.direct,
      'Contest User Redirect Url': userRanking.redirect_url,
      'Contest Social Url': userRanking.social_url
    });
  }
}
