<div class="points-breakdown" [ngClass]="{'points-breakdown-dashboard' : dashboardView === true}">
    <h1 class="profile-name" [ngClass]="{'profile-name-dashboard' : dashboardView === true}">
        Hey, <span style="color: #0ed58c;">{{ firstName }}</span>
    </h1>

    <div class="user-score-data-points">
        <img class="mm-points" src="../../../../../assets/user-dashboard/memu-bar/mm-points.svg" alt="points">
        <div *ngIf="userRankings; else zeroScore">
            <div counto [step]="10" [countTo]="userRankings.point" [countFrom]="0" [duration]="3" (countoChange)="counto = $event">
                <h1 class="user-points">
                    {{ counto | number: '1.0-0' }}
                </h1>
            </div>
        </div>
        <ng-template #zeroScore>
            <h1 class="user-points">
                0
            </h1>
        </ng-template>
    </div>



    <div class="user-rankings-item">
        <div class="user-rankings-item-icon">
            <div class="dollar-reward-container">
                <img class="dollar-symbol" src="../../../../../assets/user-dashboard/dollar-symbol.svg" alt="dollar-symbol">
                <h2 class="dollar-reward">{{ dollarReward | number: '1.0-0' }}</h2>
                <h3 class="sub-title next-target">Your next target</h3>
            </div>
            <mwl-gauge class="user-poits-gauge" [max]="100" [dialStartAngle]="-90" [dialEndAngle]="-90.001" [value]="updateGaugeScoreValue[1]" [animated]="true" [animationDuration]="4" [showValue]="false">
            </mwl-gauge>
        </div>

        <div class="redeem-now">
            <h3>REDEEM NOW</h3>
        </div>
    </div>

    <div style="width: 100%; height: 60px;">
        <div class="user-score-data-sub" style="border-right: 1px solid #ecedee;">
            <div *ngIf="dollars; else zeroPoints">
                <img class="dollar-symbol-b" src="../../../../../assets/user-dashboard/dollar-symbol.svg" alt="dollar-symbol">
                <h3 style="margin-left: -2px;" class="user-dollars"> {{ dollars | number: '1.0-0' }}</h3>
            </div>
            <ng-template #zeroPoints>
                <h3 class="user-dollars">$0</h3>
            </ng-template>
            <h3 class="sub-title">MePoints to redeem</h3>
        </div>
        <div class="user-score-data-sub" style="float: left; width: 50%; height: 100%; border-top: 1px solid #ecedee; text-align: center;">
            <h3 class="user-dollars" style="color: #272d2d;">
                {{ mePointsTo | number: '1.0-0' }}
            </h3>
            <h3 class="sub-title">MePoints to {{ dollarReward | currency:'USD':true: '1.0-0' }}</h3>
        </div>
    </div>
</div>
<svg style="height: 0" width="100" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg" overflow="visible" filterUnits="userSpaceOnUse">
  <defs>
    <filter id="image-dot" x="0%" y="0%" width="100%" height="100%">
      <feImage xlink:href="../../../../../assets/user-dashboard/user-points/img-dot.png" />
    </filter>
    <linearGradient id="user-points-gauge">
      <stop offset="10%" stop-color="#0ed58c" />
      <stop offset="90%" stop-color="#ffdf52" />
    </linearGradient>
  </defs>
  <marker *ngIf="!safariMarker;" id="gauge-dot" markerHeight="15" markerWidth="15" orient="auto" refX="4" refY="5"
    overflow="visible">
    <circle id='top' cx="5" cy="5" r="1.2" style="filter:url(#image-dot);" />
  </marker>
  <marker *ngIf="safariMarker;" id="gauge-dot" markerHeight="13" markerWidth="13" orient="auto" refX="4" refY="5">
    <circle cx="5" cy="5" fill="#0ed58c" r="1" stroke="#FCFCFC" stroke-width="0.3"></circle>
  </marker>
</svg>