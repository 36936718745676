import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';

@Injectable()

export class DataBreakdownService {

  constructor(private http: HttpClient) { }

  processingUserData = new Subject<any>();

  getFileUploadsummary() {
    return this.http.get<any>(`user/profileSummary`);
  }

  getFileUploadStatus() {
    return this.http.get<any>(`user/tempFileUploadSummary`);
  }

  processUserData(processing: {}) {
    // console.log('PROCESSING: ', processing);
    this.processingUserData.next({ processing: processing });
  }

}
