import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()

export class RankingsService implements OnInit {
  constructor(
    private http: HttpClient,
  ) { }
  ngOnInit() { }

  getReferral() {
    return this.http.get<any>(`user/kickoffLabsReferralCode`)
      .map(response => {
        const data = response;
        return data;
      });
  }

  getLeaderboardData() {
    return this.http.get<any>(`prod/MonetizeMeUIConfig-prod`)
      .map(response => {
        const data = response;
        return data;
      });
  }
  getRankings() {
    return this.http.get<any>(`api/v1/account/balance`)
      .map(response => {
        const data = response;
        return data;
      });
  }

  // getRankingsNew() {
  //   // return this.http.get<any>(`user/findKickoffLabsUserPoints?email=${encodeURIComponent(email)}`)
  //   return this.http.get<any>(`api/v1/account/balance`)
  //     .map(response => {
  //       const data = response;
  //       return data;
  //     });
  // }

  getProgressBar() {
    return this.http.get<any>(`user/dataConnectProgress`)
      .map(response => {
        const data = response;
        return data;
      });
  }

  copyWidgetLink(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${val}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  marxToUSD(points) {
    return this.http.post<any>(`utils/marxToUSD`, { marx: points })
      .map(response => {
        const data = response;
        return data;
      });
  }

  pluginLinks() {
    window.open('/static/extension.html', '_blank');
  }
}
