import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService, browserType } from '../../services';

@Component({
  selector: 'app-page-steps',
  templateUrl: './page-steps.component.html',
  styleUrls: ['./page-steps.component.css']
})
export class PageStepsComponent implements OnInit {
  stepsTop = true;
  stepsBottom = false;
  scrollTop = 0;
  browserType: {};
  scrollTimeout = 0;
  scrHeight = window.innerHeight;
  constructor(
    public router: Router,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.browserType = this.utilService.browserType();
    if (this.browserType === browserType.isFirefox || this.browserType === browserType.isSafari) {
      this.scrollTimeout = 100;
    }
  }

  @HostListener('window:scroll', [])

  onScroll() {
    if (this.router.url.includes('/contest/rankings')) {
      const verticalOffset = window.pageYOffset || document.documentElement.scrollTop;
      if (verticalOffset > this.scrollTop) {
        if (this.browserType !== browserType.isSafari) {
          this.scrollTop = verticalOffset;
          this.scrollToDetails();
        } else {
          setTimeout(() => {
            this.scrollToDetails();
            setTimeout(() => {
              this.scrollTop = verticalOffset;
            }, 500);
          }, 100);
        }
      } else {
        if (this.browserType !== browserType.isSafari) {
          this.scrollTop = verticalOffset;
          this.scrollToTop();
        } else {
          setTimeout(() => {
            this.scrollToTop();
            setTimeout(() => {
              this.scrollTop = verticalOffset;
            }, 500);
          }, 100);
        }
      }
    }
  }
  scrollToDetails() {
    if (this.stepsTop) {
      setTimeout(() => {
        window.scrollTo({
          top: this.scrHeight,
          left: 0,
          behavior: 'smooth'
        });
        this.stepsTop = false;
        this.stepsBottom = true;
      }, this.scrollTimeout);
    }

  }
  scrollToTop() {
    if (!this.stepsTop) {
      setTimeout(() => {
        window.scrollTo({
          top: -this.scrHeight,
          left: 0,
          behavior: 'smooth'
        });
        this.stepsTop = true;
        this.stepsBottom = false;
      }, this.scrollTimeout);
    }

  }
}
