import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()

export class FinancesService implements OnInit {

  currentUser: any;

  ngOnInit() { }

  constructor(private http: HttpClient) { }

  getFinances() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(`user/advertisers`)
      .map(response => {
        const data = response;
        return data;
      });
  }

  createAccessToken(publicToken: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post<any>(`finances/plaid/accessToken?publicToken=${publicToken}`, { headers: headers })
      .map(response => {
        return response;
      });
  }

  retrieveTransactions() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post<any>(`finances/plaid/transactions`, { headers: headers })
      .map(response => {
        return response;
      });
  }

}
