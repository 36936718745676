import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  hideQa = [];
  showPluginBanner = true;
  questionAndAnswers: any[] = [
    {
      'question': 'Are you sharing my data?',
      'answer': 'Your data is currently not being shared with any 3rd parties. At a future date we may anonymize the data and use it for advertising and market research purposes.  Before this happens, though -- we will get explicit approval from you.'
    },
    {
      'question': 'I have installed the Monetizeme Plug-in and Linked a Credit Card.  What do I have to do to earn points?',
      'answer': 'Points are accrued by a combination of credit card activity (via linked credit card); and activity logged on your chrome browser. With MonetizeMe, the more data you create, the more “digital credit” rewards points you receive. Data is created when you browse the internet or purchase an item with your linked credit card.'
    },
    {
      'question': 'I have installed the Monetizeme Plug-in. When will I receive money in exchange for my data?',
      'answer': 'With MonetizeMe, you’ll earn MePoints which equate to spending​ power on our marketplace. You can access our marketplace by ​downloading our App (iOS or Android).  We are constantly adding items​ to the marketplace to ensure you have a growing selection. Just like you would​ Earn “credit card reward points” dependent on the amount you spend. With MonetizeMe,  the more data you create, the more “digital credit” rewards points you receive.'
    },
    {
      'question': 'I installed the Monetizeme Plug-in but don’t see any progress. Have you already started to collect my data?',
      'answer': 'Please check that your plugin is active and that you have not selected to pause activity. If you still think there’s an issue, please send an email to help@monetizeme.com and someone from our team will get back to you with more details.'
    },
    {
      'question': 'What personal data are you monetizing for me?',
      'answer': 'Currently we are simply collecting browsing activity and anonymizing the data so it’s not link to a person’s personal information. For more information, please contact us at help@monetizeme.com and someone from our team will get back to you with more details.'
    },
    {
      'question': 'I no longer want to participate in Monetizeme. How do I erase all my data from your plug-in?',
      'answer': 'You may pause the recording of browsing activity at anytime by selecting pause on the plugin. If you would like to forfeit your place in the contest and all your points, you can uninstall the plugin. If you have further questions, please send an email to help@monetizeme.com and someone from our team will get back to you with more details.'
    },
    {
      'question': 'What will Monetizeme do with my data?',
      'answer': 'Please send an email to help@monetizeme.com and someone from our team will get back to you with more details.'
    },
    {
      'question': 'How do I invite friends to participate in Monetizeme?',
      'answer': 'Visit monetizeme.com and login to see details on how you can refer friends. You can receive points for referrals who successfully install the chrome plugin. You can share a unique referral link that will track the referrals you make and ensure you’re rewarded for successful referrals.'
    },
    {
      'question': 'Who do I contact for customer support?',
      'answer': 'Please send an email to help@monetizeme.com and someone from our team will get back to you with more details.'
    }
  ];

  constructor() { }

  ngOnInit() {
    this.expandQa(0);
  }
  expandQa(i) {
    for (let indx = 0; indx < this.hideQa.length; indx++) {
      if (this.hideQa[indx] === true) {
        this.hideQa[indx] = false;
      }
    }
    this.hideQa[i] = !this.hideQa[i];
  }


}
