import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()

export class AdvtsService {

  currentUser: any;
  constructor(private http: HttpClient) { }

  getAdvertisers() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(`user/advertisers`)
      .map(response => {
        const data = response;
        return data;
      });
  }

}
