import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()

export class UserDataPermissionsService {


  constructor(private http: HttpClient) { }

  getUserDataPermissions() {
    return this.http.get<any>(`user/dataPermissions/preference`);
  }

  postPermissions(payload) {
    return this.http.post<any>(`user/dataPermissions/preference`, payload);
  }

}
