<div class="body-layer" *ngIf="severErrorPopup"></div>
<div class="main-popup" *ngIf="severErrorPopup">
    <img src="../../assets/ver-3/error-states/upload-error.svg" alt="error" style="margin-bottom: 50px;">
    <h3 class="pop-up-headline">Something Went Wrong...</h3>
    <h4 class="pop-up-sub-headline">
        A server side error has occurred. Please click the button below to try again.
    </h4>
    <button class="app-popup-button reload-btn" type="button" (click)="onReload()">Try Again</button>
</div>
<div class="main-wrapper">
    <div *ngIf="!(router.url.includes('plugin') || router.url.includes('welcome') || router.url.includes('market') || router.url.includes('dashboard'))" id="fb-background" class="fb-background"></div>
    <div class="contact-us" *ngIf="!router.url.includes('plugin-welcome') && !router.url.includes('data-welcome')" style="position: fixed; right: 1rem; bottom: 2rem; margin-right: 100px">
        <app-contact-us></app-contact-us>
    </div>
    <main [@routerTransition]="getState(outlet)" class="app-main-container" [ngClass]="{'full-width' : (router.url.includes('plugin') || router.url.includes('welcome') || router.url.includes('market') || router.url.includes('dashboard') || router.url.includes('faq'))}">
        <app-page-steps></app-page-steps>
        <div class="fb-page-header" *ngIf="!(router.url.includes('plugin') || router.url.includes('welcome') || router.url.includes('market') || router.url.includes('dashboard'))">
            <img src="../../../assets/ver-3/logo.svg" alt="Logo" routerLink="logoRoute()">
        </div>
        <router-outlet #outlet="outlet"></router-outlet>
    </main>
</div>