import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/observable/interval';
import { RankingsService } from './../../../components/user-dashboard/menu-bar/rankings.service';
import { UtilService, browserType } from './../../../services/util.service';

@Component({
  selector: 'app-points-gauge',
  templateUrl: './points-gauge.component.html',
  styleUrls: ['./points-gauge.component.css']
})
export class PointsGaugeComponent implements OnInit, OnDestroy {
  currentUser: any;
  userEmail: any;
  userRankings: any;
  interval: any;
  checkUserRankings: any;
  dollars: number;
  marx: number;
  totalPoints = 5000;
  dollarReward = 5;
  gaugeCalc = 0;
  dashboardView = false;
  mePointsTo = 0;
  firstName = '';
  browserType: {};
  safariMarker = false;

  // Gauges
  percentageValue: (value: number) => string;
  updateGaugeScoreValue: any = {
    1: 0,
  };
  gaugeScoreValue = 0;

  constructor
    (public router: Router,
      private rankingsService: RankingsService,
      private utilService: UtilService
    ) { }

  ngOnInit() {

    this.browserType = this.utilService.browserType();
    if (this.browserType === browserType.isSafari) {
      this.safariMarker = true;
    }

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.firstName = this.currentUser.responseData.firstName;
      this.getUserRankings();
      this.checkUserRankings = Observable.interval(10000)
        .subscribe(() => {
          this.getUserRankings();
        });
    } else {
      this.setContestGaugesZero();
    }

    if (this.router.url.includes('/dashboard/home')) {
      this.dashboardView = true;
    }

    // this.getRN();
  }

  // getRN() {
  //   // console.log('CHECK');
  //   this.rankingsService.getRankingsNew()
  //     .subscribe(
  //       (userRankings: any) => {
  //         if (userRankings) {
  //           console.log('NEW USER\'S RENKINGS: ', userRankings);
  //         }
  //         console.log('NEW USER\'S RENKINGS: ', userRankings);
  //       },
  //       (error) => {
  //         console.log(error);
  //       });
  // }

  // USER RANKINGS
  getUserRankings() {
    this.rankingsService.getRankings()
      .subscribe(
        (userRankings: any) => {
          if (userRankings) {
            this.userRankings = userRankings;
            this.setContestGauges();
            // console.log('userRankings: ', this.userRankings.point);
            this.rankingsService.marxToUSD(this.userRankings.point)
              .subscribe(
                (dollars: any) => {
                  this.dollars = Math.floor(dollars);
                },
                (error) => console.log(error));
          }
          // console.log('USER\'S RENKINGS: ', userRankings);
        },
        (error) => {
          console.log(error);
        });
  }

  // SET GAUGES
  setContestGauges() {
    // this.userRankings.point = 13000;
    this.totalPoints = Math.ceil(this.userRankings.point / 5000) * 5000;
    this.gaugeCalc = Math.floor(this.userRankings.point / 5000) * 5000;
    this.dollarReward = this.totalPoints / 1000;
    this.mePointsTo = (this.dollarReward * 1000) - this.userRankings.point;
    // console.log('this.totalPoints: ', this.totalPoints);
    // console.log('this.dollarReward: ', this.dollarReward);

    this.gaugeScoreValue = Math.floor(((this.userRankings.point - this.gaugeCalc) / (this.totalPoints - this.gaugeCalc)) * 100);
    if (isNaN(this.gaugeScoreValue)) {
      this.gaugeScoreValue = 100;
    }
    // console.log('this.gaugeScoreValue: ', this.gaugeScoreValue);
    const updateValues = (): void => {
      this.updateGaugeScoreValue = {
        1: this.gaugeScoreValue
      };
    };
    setTimeout(() => {
      updateValues();
    }, 500);
  }

  setContestGaugesZero() {
    const updateValues = (): void => {
      this.updateGaugeScoreValue = {
        1: 0
      };
    };
    setTimeout(() => {
      updateValues();
    }, 500);
  }


  ngOnDestroy(): void {
    if (this.checkUserRankings) {
      this.checkUserRankings.unsubscribe();
    }

  }

}
