<div class="plugin-hot-offers-container">
    <div *ngIf="!featuredSweepstakes">
        No Featured Sweepstakes
    </div>
    <ul class="plugin-hot-offers">
        <li *ngFor="let ffs of featuredSweepstakes">
            <a target="_blank" href="https://monetizeme.com/#/dashboard/redeem">
                <img (click)="onGetApp(ffs, 'Featured Item', findOfferIndex(featuredSweepstakes, ffs))" [src]="ffs.imageUrl ? ffs.imageUrl : '../../../../assets/market/comingsoonbg.png'" />
            </a>
        </li>
    </ul>
</div>