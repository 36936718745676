import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ErrorStateComponent } from './components/error-state/error-state.component';
import { AuthGuard } from './guards/index';
const appRoutes: Routes = [
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  {
    path: '',
    component: AppComponent,
  },
  { path: 'sign-up', loadChildren: './components/user-registration/user-registration.module#UserRegistrationModule' },
  { path: 'data', loadChildren: './components/data/upload-data.module#UploadModule', canLoad: [AuthGuard] },
  { path: 'contest', loadChildren: './components/plugin/plugin.module#PluginModule', canLoad: [AuthGuard] },
  { path: 'err-state', component: ErrorStateComponent, data: { state: 'err-state' } },

  {
    path: 'welcome',
    loadChildren: './modules/landingpage/landingpage.module#LandingpageModule'
  },
  // Plugin:
  {
    path: 'plugin',
    loadChildren: './modules/plugin-iframe/plugin-iframe.module#PluginIframeModule'
  },
  {
    path: 'dashboard',
    loadChildren: './components/user-dashboard/user-dashboard.module#UserDashboardModule',
    canLoad: [AuthGuard]
  },
  // Otherwise redirect to
  { path: '**', redirectTo: 'contest/rankings', pathMatch: 'full' }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
