﻿import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthGuard implements CanLoad {

  constructor(private router: Router) { }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('currentUser')) {
      return true;
    }
    // not logged in so redirect to welcome page:
    console.log('NOT LOGGED IN');
    this.router.navigate(['/welcome']);
    return false;
  }


}
