﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class AuthenticationService {
  userId = '';

  constructor(private http: HttpClient) { }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  setDtmxCookie() {
    if (localStorage.getItem('currentUser')) {
      this.setCookie('dtmx-cookie', localStorage.getItem('currentUser'), 20 * 365, ' path=/');
    }
  }


  login(username: string, password: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>('api/v1/login', { username: username, password: password }, { headers: headers })
      .map(response => {
        // Login successful if there's a jwt token in the response. Only if access equals customer
        if (response && response.responseData.token && response.responseData.access === 'customer') {
          // Store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.setCookie('dtmx-cookie', JSON.stringify(response), 20 * 365, ' path=/');
        }
        return response;
      });
  }

  verifyEmail(code: string) {
    let headers = new HttpHeaders();
    const userId = localStorage.getItem('userId');
    headers = headers.set('Content-Type', 'application/json').set('X-DTMX-Verification-Code', code);
    return this.http.post<any>(`api/v1/verify/${userId}`, {}, { headers: headers })
      .map(response => {
        // Login successful if there's a jwt token in the response. Only if access equals customer
        if (response && response.responseData.token && response.responseData.discr === 'customer') {
          // Store user details and jwt token in local storage to keep user logged in between page refreshes
          // Handle user key
          response.responseData.userId = response.responseData.id;
          delete response.responseData.id;
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.setCookie('dtmx-cookie', JSON.stringify(response), 20 * 365, ' path=/');
          localStorage.removeItem('userId');
          return response;
        }
      });
  }

  verifyEmailResetPassword(code, password, username) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('X-DTMX-Verification-Code', code);
    return this.http.post<any>(`api/v1/account/change-password/${username}`, { plainPassword: password }, { headers: headers })
      .map(response => {
        // Login successful if there's a jwt token in the response. Only if access equals customer
        if (response && response.responseData.token && response.responseData.discr === 'customer') {
          // Store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.setCookie('dtmx-cookie', JSON.stringify(response), 20 * 365, ' path=/');
          // localStorage.removeItem('userId');
        }
        return response;
      });
  }

  resendCode() {
    const userPhone = localStorage.getItem('phone');
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>('api/v1/account/verify', { 'phone': userPhone }, { headers: headers })
      .map(response => {
        const data = response;
        return data;
      });
  }

  resetPassword(phone: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post<any>(`api/v1/account/reset-password`, { phone: phone }, { headers: headers })
      .map(response => {
        return response;
      });
  }

  forgotUsername(phone: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.post<any>(`api/v1/account/forgot-username`, { phone: phone }, { headers: headers })
      .map(response => {
        return response;
      });
  }


  verifyDisplayUser(code) {
    const phone = localStorage.getItem('phone');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('X-DTMX-Verification-Code', code);
    return this.http.post<any>(`api/v1/account/display-username`, { phone: phone }, { headers: headers })
      .map(response => {
        return response;
      });
  }

  hermesUserLogin(userId) {
    return this.http.get<any>(`user/state?userId=${userId}`)
      .map(response => {
        return response;
      });
  }

  logout() {
    localStorage.clear();
  }
  softLogout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('uploadResponse');
  }
}
