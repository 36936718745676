import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()

export class SignUpService {

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  private headers;

  creatUser(user: object) {
    return this.http.post<any>('api/v1/account', user, { headers: this.headers })
      .map(response => {
        const data = response;
        return data;
      });

  }

  countryCodes() {
    return this.http.get<any>(`prod/CountryCodeConfig-prod`)
      .map(response => {
        const data = response;
        return data;
      });
  }

  fireAffiliatePostback(url: string) {
    return this.http.get(url);
  }


}
