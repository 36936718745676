import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable()

export class UploadService {

  constructor(private http: HttpClient) {}

  private currentUser;
  fileUploaded() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post<any>('user/uploadStatus',
    { id: this.currentUser.userId, facebookFileStatus: 'UPLOAD_STARTED', primaryEmail: this.currentUser.email })
      .map(response => {
        const data = response;
        return data;
      });
  }


}
