import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs/Subject';

@Injectable()

export class UserPermissionsService {

  private hermesAPI: string = environment.hermesAPI;
  socialSourcesData = new Subject<any>();

  constructor(private http: HttpClient) { }

  getUserPermissions() {
    return this.http.get<any>(`social/sources`).map(response => {
      const data = response;
      // console.log('USER PERMISSIONS: ', data);
      this.socialSourcesData.next({ socialSourcesData: data });
      return data;
    });
  }

  authorizeUser(token: String, source: String) {
    fetch(`${this.hermesAPI}/social/authorize?source=${source}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.text())
      .then(responseBody => {
        window.location.href = responseBody;
      });
  }

  getCallbackUrl(param) {

    const currentSource = localStorage.getItem('currentSource');
    return this.http.get<any>(`social/callback?source=${currentSource}&callbackUri=${encodeURIComponent(param)}`)
      .map(response => {
        const data = response;
        return data;
      });
  }
}
