<div class="banner-wrapper" [style.display]="showPluginBanner ? 'block' : 'none'">
    <div class="banner-main">
        <div class="cross-wrapper" (click)="showPluginBanner = false">
            <span class="cross">X</span>
        </div>
        <a href="/static/extension.html" target="_blank">
            <img src="/assets/user-dashboard/faq/plugin-no-shadow.png" alt="Install plugin to earn extra points">
        </a>
    </div>
</div>
<div class="user-dashboard-faq-container">
    <div class="faq-container" *ngFor="let question of questionAndAnswers; let i = index; let lastItem = last;">
        <div (click)="expandQa(i)" style="cursor: pointer; width: 100%; height: 30px;">
            <div class="faq-item-container">
                <h2 class="faq-question">
                    {{ question.question }}
                </h2>
                <div *ngIf="hideQa[i]">
                    <p class="details-sub-headline">
                        {{ question.answer }}
                    </p>
                </div>
                <div class="arrow-container">
                    <div *ngIf="hideQa[i]===true; else collapse">
                        <img src=" ../../../assets/ver-3/faq-collapse.svg" alt="faq-collapse">
                    </div>
                    <ng-template #collapse>
                        <img src="../../../assets/ver-3/faq-expand.svg" alt="faq-expand">
                    </ng-template>
                </div>
            </div>
            <hr *ngIf="!lastItem" class="faq-divider">
        </div>
    </div>

</div>