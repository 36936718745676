import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';

@Injectable()

export class HomeService implements OnInit {
  serverErr = new Subject<any>();
  ngOnInit() { }

  constructor(private http: HttpClient) { }

  onServerError(serverr: boolean) {
    this.serverErr.next({ serverr: serverr });
  }

}
