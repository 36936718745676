import { Component, OnInit } from '@angular/core';
import { MarketPlaceService } from './../../../components/user-dashboard/marketplace/marketplace.service';

@Component({
  selector: 'app-sweepstakes',
  templateUrl: './sweepstakes.component.html',
  styleUrls: ['./sweepstakes.component.css']
})
export class SweepstakesComponent implements OnInit {
  featuredSweepstakes: any;
  currentUser: any;
  constructor(
    private marketPlaceService: MarketPlaceService
  ) { }

  ngOnInit() {
    document.body.style.overflowX = 'hidden';
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.fetchCards();
    }

  }

  fetchCards() {
    this.marketPlaceService.marketPlaceOffers(1, 1).subscribe(
      response => this.featuredSweepstakes = response ? response.products : []
    );
  }
}
