import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { HomeService } from '../components/home/home.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  serverr = false;
  private currentUser: any;
  private url: string = environment.signUpApi;
  private hermesAPI: string = environment.hermesAPI;
  private backendAPI: string = environment.backendAPI;
  private apolloAPI: string = environment.apolloAPI;
  private configUrl: string = environment.configUrl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homeService: HomeService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    let apiReq = req.clone({ url: `${this.url}/${req.url}` });

    if (req.url.includes('user/allCards') && req.method === 'GET' || req.url.includes('mailChimp')) {
      apiReq = req.clone({ url: `${this.hermesAPI}/${req.url}` });
    }
    if (req.url.includes('facebook-upload-artifacts') && req.method === 'GET') {
      apiReq = req.clone({ url: `https://s3.amazonaws.com/${req.url}` });
    }
    if (req.url.includes('leaderboard') && req.method === 'GET') {
      apiReq = req.clone({ url: `https://api.kickofflabs.com/${req.url}` });
    }
    if (req.url.includes('user/findKickoffLabsUserPoints') && req.method === 'GET') {
      apiReq = req.clone({ url: `${this.hermesAPI}/${req.url}` });
    }
    if (req.url.includes('api/v1/account/balance') && req.method === 'GET') {
      apiReq = req.clone({ url: `${this.backendAPI}/${req.url}` });
    }
    if (req.url.includes('prod/MonetizeMeUIConfig-prod') && req.method === 'GET') {
      apiReq = req.clone({ url: `${this.configUrl}` });
    }
    if (req.url.includes('prod/CountryCodeConfig-prod') && req.method === 'GET') {
      apiReq = req.clone({ url: `https://config.monetizeme.com/cc/${req.url}` });
    }
    if (req.url.includes('forgot-username') && req.method === 'POST'
      || req.url.includes('display-username') && req.method === 'POST'
    ) {
      apiReq = req.clone({ url: `${this.backendAPI}/${req.url}` });
    }


    if (this.currentUser && this.currentUser.responseData.token) {
      let headers = new HttpHeaders();
      headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.currentUser.responseData.token);
      if (
        req.url.endsWith('user/signup') && req.method === 'POST'
        || req.url.includes('utils/marxToUSD') && req.method === 'POST'
        || req.url.includes('notification/invite') && req.method === 'POST'
        || req.url.includes('user/sendWelcomeEmail') && req.method === 'POST'
        || req.url.includes('user/state?userId') && req.method === 'GET'
        || req.url.includes('user/allCards') && req.method === 'GET'
        || req.url.includes('user/findSelection') && req.method === 'GET'
        || req.url.includes('user/findKickoffLabsUserPoints') && req.method === 'GET'
        || req.url.includes('social/callback') && req.method === 'GET'
        || req.url.includes('user/dataPermissions/preference')
      ) {
        apiReq = req.clone({ url: `${this.hermesAPI}/${req.url}`, headers: headers });
      } else if (req.url.includes('leaderboard') && req.method === 'GET') {
        apiReq = req.clone({ url: `https://api.kickofflabs.com/${req.url}` });
      } else if (req.url.includes('prod/MonetizeMeUIConfig-prod') && req.method === 'GET') {
        apiReq = req.clone({ url: `${this.configUrl}` });
      } else if (req.url.includes('products/giftcards') && req.method === 'GET') {
        apiReq = req.clone({ url: `${this.backendAPI}/${req.url}`, headers: headers });
      } else if (req.url.includes('products/product') && req.method === 'GET') {
        apiReq = req.clone({ url: `${this.backendAPI}/${req.url}`, headers: headers });
      }
      else if (req.url.includes('api/v1/account/balance') && req.method === 'GET') {
        apiReq = req.clone({ url: `${this.backendAPI}/${req.url}`, headers: headers }); apiReq = req.clone({ url: `${this.backendAPI}/${req.url}`, headers: headers });
      }
      else if (
        req.url.includes('finances/plaid/accessToken')
        || req.url.includes('finances/plaid/transactions')
        || req.url.includes('app/permissions')) {
        apiReq = req.clone({ url: `${this.apolloAPI}/${req.url}`, headers: headers });
      } else {
        apiReq = req.clone({ url: `${this.hermesAPI}/${req.url}?userId=${this.currentUser.responseData.userId}`, headers: headers });
      }

    }

    return next.handle(apiReq).do((event: HttpEvent<any>) => {
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        const errStatus = err.status.toString();
        if (err.status === 401) {
          this.router.navigate(['/sign-up'],
            { queryParams: { expired: 'true' } });
          localStorage.clear();
        } else if (err.status === 0 || errStatus.startsWith('5')) {
          // this.serverr = true;
          // this.homeService.onServerError(this.serverr);
        }
      }
    });
  }
}
