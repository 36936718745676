import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()

export class TagsService {

  constructor(private http: HttpClient) { }

  getTags() {
    return this.http.get<any>(`user/interests`);
  }

}
