import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {UtilService} from '../../../services';

@Injectable()
export class MainService {
  private headers;
  statsAPIUrl: string = '/utils/stats?name=monetizeMe';

  constructor(private _utilService: UtilService) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  /**
   * Get user statistics data
   */
  getUserStats() {
    const statsUri = environment.hermesAPI + this.statsAPIUrl;
    return new Observable((observer) => {
      fetch(statsUri)
        .then(response => (response ? response.json() : {}))
        .then((data: any) => {
          if (data) {
            observer.next(data['userCount'] ? data['userCount'] : 0); // Getting user count
          } else {
            observer.next(0);
          }
        });
    });
  }

  /**
   * Creating the user session
   */
  createSDKUser() {
    const dtmxCookie = this._utilService.getCookie('dtmx-cookie');
    if (localStorage.getItem('sub1') && localStorage.getItem('seccode') && (!dtmxCookie && 0 === dtmxCookie.length)) {
      const url = environment.sdkUserUrl;
      const req = new Request(url, {
        method: 'GET',
      });

      fetch(req)
        .then(response => (response ? response.json() : {}))
        .then((data: any) => {
          const base64Token = data.data.token.split('.')[1];
          const base64 = base64Token.replace('-', '+').replace('_', '/');
          const usr = JSON.parse(window.atob(base64));
          usr.firstName = '';
          usr.lastName = '';
          usr.username = '';
          usr.userId = usr.userHash + '_' + localStorage.getItem('seccode') + '_' + localStorage.getItem('sub1');
          const cookieData = {
            responseCode: 200,
            responseData: usr,
            responseMessage: null,
          };
          this.setCookie('dtmx-cookie', JSON.stringify(cookieData), 20 * 365, ' path=/');
        });
    }
  }

  /**
   * Set cookie with name and its value along with its expiry date
   * @param name string
   * @param value any
   * @param expireDays number
   * @param path string
   */
  setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }
}
