import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';

export enum browserType {
  isFirefox = 'isFirefox',
  isSafari = 'isSafari',
  isChrome = 'isChrome'
}

@Injectable()
export class UtilService {
  constructor() { }

  public getSeccode() {
    const lsSeccode = localStorage.getItem('seccode');
    const cookieSeccode = this.getCookie('dtmx-seccode');

    if (lsSeccode && lsSeccode.length > 0) {
      return lsSeccode;
    } else if (cookieSeccode && cookieSeccode.length > 0) {
      return cookieSeccode;
    }

    return '';
  }

  public getSub1() {
    const lsSub1 = localStorage.getItem('sub1');
    const cookieSub1 = this.getCookie('dtmx-sub1');

    if (lsSub1 && lsSub1.length > 0) {
      return lsSub1;
    } else if (cookieSub1 && cookieSub1.length > 0) {
      return cookieSub1;
    }

    return '';
  }

  public getUserData() {
    if (this.getCookie('dtmx-cookie')) {
      const dtmxCookie = JSON.parse(this.getCookie('dtmx-cookie'));
      if (dtmxCookie && dtmxCookie.responseData) {
        return dtmxCookie.responseData;
      }
    }
    return undefined;
  }

  public getRedirect() {
    const lsRedirect = localStorage.getItem('redirect');
    const cookieRedirect = this.getCookie('dtmx-redirect');

    if (lsRedirect && lsRedirect.length > 0) {
      return lsRedirect.toString();
    } else if (cookieRedirect && cookieRedirect.length > 0) {
      return cookieRedirect.toString();
    }

    return '';
  }

  public getDirect() {
    const lsDirect = localStorage.getItem('direct');
    const cookieDirect = this.getCookie('dtmx-direct');

    if (lsDirect && lsDirect.length > 0) {
      return lsDirect;
    } else if (cookieDirect && cookieDirect.length > 0) {
      return cookieDirect;
    }

    return '';
  }

  public getReferralCode() {
    const lsReferralCode = localStorage.getItem('referralCode');
    const cookieReferralCode = this.getCookie('dtmx-referralCode');

    if (lsReferralCode && lsReferralCode.length > 0) {
      return lsReferralCode;
    } else if (cookieReferralCode && cookieReferralCode.length > 0) {
      return cookieReferralCode;
    }

    return '';
  }

  public getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public getQRImageUrl(source: string) {
    let baseUrl = `${environment.monetizemeUrl}/mobile-app.html?source=qr_${source}`;
    if (this.getUserData() && this.getUserData().userId) {
      baseUrl += `&user=${btoa(this.getUserObject())}`;
    }
    return baseUrl;
  }

  public browserType() {
    let brType: {};
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    if (isFirefox) {
      brType = browserType.isFirefox;
    }
    if (isSafari) {
      brType = browserType.isSafari;
    }
    if (isChrome) {
      brType = browserType.isChrome;
    }
    return brType;
  }

  private getUserObject() {
    return JSON.stringify({
      userId: this.getUserData().userId
    });
  }
}
