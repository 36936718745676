import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  fbPhone = '379-7644';
  pluginPhone = '383-4772';
  showPhone = '';

  constructor() { }

  ngOnInit() {
    if (localStorage.getItem('pluginFlow')) {
      this.showPhone = this.pluginPhone;
    } else {
      this.showPhone = this.fbPhone;
    }
  }

}
